import { IterableDataType } from "@types";
import SPfetch from "../services/SPFetch.class";
import { components } from "src/assets/Types/ws-marketing";
import { getFormattedTime } from "../assets/util-scripts/helpers";

export const iterableSubmit = (data: IterableDataType) => {
  const endpoint = `${process.env.GATSBY_WS_URL}/api/iterable/userSubmit`;
  data.eventName = data.eventName ? data.eventName : "newUserSignup";

  const UTM_CAMPAIGN = sessionStorage.getItem("utm_campaign");
  const UTM_MEDIUM = sessionStorage.getItem("utm_medium");
  const UTM_SOURCE = sessionStorage.getItem("utm_source");

  if (UTM_CAMPAIGN) data["UTM_CAMPAIGN"] = UTM_CAMPAIGN;
  if (UTM_MEDIUM) data["UTM_MEDIUM"] = UTM_MEDIUM;
  if (UTM_SOURCE) data["UTM_SOURCE"] = UTM_SOURCE;

  return SPfetch.post(endpoint, data);
};

export const iterableEmailCaptured = (email: string) => {
  const endpoint = `${process.env.GATSBY_WS_URL}/api/iterable/userSubmit`;

  const UTM_CAMPAIGN = sessionStorage.getItem("utm_campaign");
  const UTM_MEDIUM = sessionStorage.getItem("utm_medium");
  const UTM_SOURCE = sessionStorage.getItem("utm_source");

  const data: components["schemas"]["IterableModel"] = {
    SIGN_UP_SOURCE: "Website - Sign Up Form",
    eventName: "newUserSignup",
    startTrialFlowAt: getFormattedTime(),
    email,
  };

  if (UTM_CAMPAIGN) data["UTM_CAMPAIGN"] = UTM_CAMPAIGN;
  if (UTM_MEDIUM) data["UTM_MEDIUM"] = UTM_MEDIUM;
  if (UTM_SOURCE) data["UTM_SOURCE"] = UTM_SOURCE;

  SPfetch.post(endpoint, data);
};
