import * as React from "react";
import { useEffect } from "react";
import { Cookie } from "../../utils/Cookie";
import { StringParam, useQueryParam } from "use-query-params";

const GlobalQueryParams: React.FunctionComponent = () => {
  const [msclkid] = useQueryParam("msclkid", StringParam);
  const [fbclid] = useQueryParam("fbclid", StringParam);
  const [li_fat_id] = useQueryParam("li_fat_id", StringParam);
  const [consultcall] = useQueryParam("consultcall", StringParam);
  const [ttclid] = useQueryParam("ttclid", StringParam);
  const [utmCampaign] = useQueryParam("utm_campaign", StringParam);
  const [utmMedium] = useQueryParam("utm_medium", StringParam);
  const [utmSource] = useQueryParam("utm_source", StringParam);
  const [gclidFromUrl] = useQueryParam("gclid", StringParam);
  const gclid = gclidFromUrl
    ? gclidFromUrl
    : Cookie.getCookie("_gcl_aw")?.split(".")[2];

  useEffect(() => {
    const domain = window.location.hostname;

    if (msclkid)
      Cookie.setCookie(
        "msclkid",
        msclkid,
        30,
        false,
        process.env.GATSBY_ENVIRONMENT === "dev"
          ? domain
          : ".simplepractice.com"
      );
    if (gclid)
      Cookie.setCookie(
        "gclid",
        gclid,
        30,
        false,
        process.env.GATSBY_ENVIRONMENT === "dev"
          ? domain
          : ".simplepractice.com"
      );
    if (fbclid) {
      Cookie.setCookie(
        "fbclid",
        fbclid,
        30,
        false,
        process.env.GATSBY_ENVIRONMENT === "dev"
          ? domain
          : ".simplepractice.com"
      );
    }
    if (ttclid)
      Cookie.setCookie(
        "ttclid",
        ttclid,
        30,
        false,
        process.env.GATSBY_ENVIRONMENT === "dev"
          ? domain
          : ".simplepractice.com"
      );
    if (li_fat_id)
      Cookie.setCookie(
        "li_fat_id",
        li_fat_id,
        30,
        false,
        process.env.GATSBY_ENVIRONMENT === "dev"
          ? domain
          : ".simplepractice.com"
      );
    if (consultcall)
      Cookie.setCookie(
        "consultcall",
        consultcall,
        30,
        false,
        ".simplepractice.com"
      );
    if (utmCampaign?.toLowerCase()?.includes("pmax"))
      sessionStorage.setItem("pmax", "true");

    if (utmCampaign) sessionStorage.setItem("utm_campaign", utmCampaign);
    if (utmMedium) sessionStorage.setItem("utm_medium", utmMedium);
    if (utmSource) sessionStorage.setItem("utm_source", utmSource);
  }, [
    msclkid,
    fbclid,
    li_fat_id,
    ttclid,
    consultcall,
    utmCampaign,
    utmMedium,
    utmSource,
  ]);
  return <></>;
};

export default GlobalQueryParams;
