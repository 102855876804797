import type { GatsbyBrowser } from "gatsby";
import { getPageCount } from "./getPageCount";
import { setSessionStorageItem, getSessionStorageItem } from "./SessionStorage";
import { getUrlParam } from "@utils/getUrlParam";
import { Cookie } from "@utils/Cookie";

const handleOnRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({
  prevLocation,
  location,
}) => {
  if (typeof document !== "undefined") {
    document.body.style.marginTop = "";
  }
  setSessionItems(prevLocation);
  fbTrigger(location);
  if (
    sessionStorage.getItem("pageCount") === "0" &&
    window.location.pathname === "/" &&
    !Cookie.checkCookie("RedesignCohort")
  )
    return;
  segmentPageView(location);
};

// Here we store any click IDs in sessionStorage for potential lead captures
const params = ["fbclid", "gclid", "msclkid", "li_fat_id", "ttclid"];
params.forEach(param => {
  if (typeof window !== "undefined") {
    const urlParam = getUrlParam(param);
    if (urlParam) {
      sessionStorage.setItem(param, urlParam);
    }
  }
});

export const segmentPageView = (location: Location) => {
  let retryAttempts = 0;
  const { pathname } = location;
  const previousPath = getSessionStorageItem("previousPath");
  const hasCookie = Cookie.checkCookie("RedesignCohort");
  const isExcluded = Cookie.checkCookie("ExperimentExclude");
  let redesignTestCohort = "None";
  if (hasCookie && !isExcluded) {
    if (Cookie.getCookie("RedesignCohort") === "1") {
      redesignTestCohort = "Original";
    } else {
      redesignTestCohort = "Variant";
    }
  }
  const pageProps = {
    initiator: "User",
    object: "Page",
    action: "Viewed",
    source_application: "Marketing",
    url: pathname,
    fbclid: getUrlParam("fbclid"),
    fcb: Cookie.getCookie("_fbc"),
    gclid: getUrlParam("gclid"),
    msclkid: getUrlParam("msclkid"),
    li_fat_id: getUrlParam("li_fat_id"),
    ttclid: getUrlParam("ttclid"),
    redesign_test_cohort: redesignTestCohort,
    referrer:
      previousPath ||
      (document.referrer && new URL(document.referrer).pathname),
  };

  const segmentInterval = setInterval(() => {
    if (retryAttempts >= 20) clearInterval(segmentInterval);
    retryAttempts++;
    if (typeof window.analytics?.page !== "function") return;
    setTimeout(() => {
      window.analytics.page("Page", pageProps);
    }, 1000);
    clearInterval(segmentInterval);
  });
};

const setSessionItems = (prevLocation: Location | null) => {
  if (!prevLocation) {
    setSessionStorageItem("pageCount", "0");
    setSessionStorageItem("prevLocation", "");
    setSessionStorageItem("previousPath", "");
  } else {
    setSessionStorageItem("prevLocation", prevLocation.search);
    setSessionStorageItem("previousPath", prevLocation.pathname);
    const count = getPageCount() + 1;
    setSessionStorageItem("pageCount", `${count}`);
  }
};

const fbTrigger = (location: Location) => {
  if (process.env.NODE_ENV !== "production") return;

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined;

  let retryAttempts = 0;

  const fbInterval = setInterval(() => {
    if (retryAttempts >= 20) clearInterval(fbInterval);
    retryAttempts++;
    if (typeof window.fbq !== "function") return;
    window.fbq("track", "ViewContent", {
      content_page: pagePath,
    });
    clearInterval(fbInterval);
  }, 100);
};

export default handleOnRouteUpdate;
